// ** React Imports
import {Suspense, lazy, useState} from 'react'
import ReactDOM from 'react-dom'

// ** Redux Imports
import {Provider} from 'react-redux'
import {store} from './redux/storeConfig/store'

// ** Toast & ThemeColors Context
import {ToastContainer} from 'react-toastify'
import {ThemeContext} from './utility/context/ThemeColors'
import {ThemeConfigContext} from './utility/context/ThemeConfig'
// import ability from './configs/acl/ability'
// import {AbilityContext} from './utility/context/Can'
import {IntlProviderWrapper} from './utility/context/Internationalization'

// ** Spinner (Splash Screen)
import SpinnerComponent from "./layouts/components/spinner/Fallback-spinner";

// ** Ripple Button
import './@core/components/ripple-button'

// ** Toast
import {Toaster} from 'react-hot-toast'
// ** PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-jsx.min'

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css'

// ** React Toastify
import '@styles/react/libs/toastify/toastify.scss'

// ** Core styles
import './@core/assets/fonts/feather/iconfont.css'
import './@core/scss/core.scss'
import './assets/scss/style.scss'

// ** Service Worker
import * as serviceWorker from './serviceWorker'
// import {CreateAbilities} from "./utility/context/CreateAbilities";
import { AbilityContext } from './utility/context/Can'
import ability from './configs/acl/ability'



// ** Lazy load app
const LazyApp = lazy(() => import('./App'))
ReactDOM.render(
    <Provider store={store}>
        <Suspense fallback={<SpinnerComponent/>}>
            {/*<CreateAbilities>*/}
                <AbilityContext.Provider value={{ability}}>
                <ThemeConfigContext>
                    <ThemeContext>
                        <IntlProviderWrapper>
                            <LazyApp/>
                            <ToastContainer newestOnTop/>
                            <Toaster position="top-right" toastOptions={{className: 'react-hot-toast'}}/>
                        </IntlProviderWrapper>
                    </ThemeContext>
                </ThemeConfigContext>
                </AbilityContext.Provider>
            {/*</CreateAbilities>*/}
        </Suspense>
    </Provider>,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
