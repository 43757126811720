// ** Imports createContext function
import { createContext } from 'react'

// ** Imports createContextualCan function
import { createContextualCan } from '@casl/react'
// import {Abilities} from './CreateAbilities'
// ** Create Context
export const AbilityContext = createContext()
// export const AbilityContext = Abilities;

// ** Init Can Context
export const Can = createContextualCan(AbilityContext.Consumer)
